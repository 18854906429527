import React, { useEffect, useContext, useReducer } from "react";
import reducer from "../reducers/addprof_reducer";
import axios from "axios";

import {
  SAVE_SUCCESS,
  UPDATE_PROF_VALUE,
  SAVE_PROF_BEGIN,
  SAVE_PROF_ERROR,
  RESET_ADD_PROF_STATE,
} from "../actions";

const initialState = {
  fname: "",
  email: "",
  phone: "",
  product: "lauditor",
  cperson: "",
  country: "",
  sub_model: "free",
  countries: [],
  products: [],
  savedStatus: "",
  message: "",
  prof_error: false,
  prof_loading: false,
};

const AddProfContext = React.createContext();

export const AddProfProvider = ({ children }) => {
  const restUrl = process.env.REACT_APP_REST_ENDPOINT;
  // const rwUrl = 'https://dev.adminapi.regswatch.com'
  const rwUrl = process.env.REACT_APP_REST_ENDPOINT_REGSWATCH
  //The below line is commented because Add Professional is a Open API
  //because of sign-up using self service payment model, but with limited security access
  // const bearer_token = sessionStorage.getItem("latoken");
  // const bearer_token = process.env.REACT_APP_STATIC_TOKEN;
  const [state, dispatch] = useReducer(reducer, initialState);
  const updateInputValue = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    dispatch({ type: UPDATE_PROF_VALUE, payload: { name, value } });
  };

  const resetStateValues = () => {
    dispatch({
      type: RESET_ADD_PROF_STATE,
      payload: { savedStatus: false, prof_error: false },
    });
  };

  const saveProfessional = async () => {
    const bearer_token = sessionStorage.getItem("latoken");
    dispatch({ type: SAVE_PROF_BEGIN });
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${bearer_token}`,
        },
      };
      if(state.product === "regswatch"){
        const date = new Date();
        const sub_start_date = 
        `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`;
        const jsonData = {
          fullname: state.fname,
          email: state.email,
          country: state.country,
          contact_person: state.cperson,
          product: state.product,
          contact_phone: state.phone,
          sub_model: state.sub_model,
          sub_start_date: sub_start_date
        };
        const response = await axios.post(
          `${rwUrl}/professional`,
          jsonData
        );
        const profUserData = await response.data;
      dispatch({ type: SAVE_SUCCESS, payload: profUserData });
      }
      else{
        const jsonData = {
          fullname: state.fname,
          email: state.email,
          country: state.country,
          contact_person: state.cperson,
          product: state.product,
          contact_phone: state.phone,
          sub_model: state.sub_model,
        };
        const response = await axios.post(
          `${restUrl}/professional`,
          jsonData,
          config
        );
        const profUserData = await response.data;
        console.log(profUserData)
        dispatch({ type: SAVE_SUCCESS, payload: profUserData });
      } 
    }catch (error) {
      dispatch({ type: SAVE_PROF_ERROR });
    }
  };

  return (
    <AddProfContext.Provider
      value={{ ...state, updateInputValue, saveProfessional, resetStateValues }}
    >
      {children}
    </AddProfContext.Provider>
  );
};

export const useAddProfContext = () => {
  return useContext(AddProfContext);
};
